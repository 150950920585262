
*{
  color: white;
  /* text-align: center; */
}

.App{
  min-width: 355px;
}

/* For the header nav component */

.headerMainLogo, .jumbotronAcLogo, .footerLogo, .navLinkImg{
  filter: invert(80%) sepia(30%) saturate(100%) hue-rotate(190deg) brightness(100%) contrast(100%);

 }

.navLinkImg{
  width: 35px;

}

/* from bootstrap */

.form-control-dark {
  border-color: var(--bs-gray);
}
.form-control-dark:focus {
  border-color: #fff;
  box-shadow: 0 0 0 .25rem rgba(255, 255, 255, .25);
}

.text-small {
  font-size: 85%;
}

.dropdown-toggle {
  outline: 0;
}

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}

.b-example-divider {
  height: 3rem;
  background-color: rgba(0, 0, 0, .1);
  border: solid rgba(0, 0, 0, .15);
  border-width: 1px 0;
  box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}

.b-example-vr {
  flex-shrink: 0;
  width: 1.5rem;
  height: 100vh;
}

.bi {
  vertical-align: -.125em;
  fill: currentColor;
}

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
}

.nav-scroller .nav {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

 /* home page styling */

 .homeJumbotronDiv{
  position: relative;
  margin: auto;
  top: 0px;
  overflow: hidden;
  width: 100%;
  max-height: 600px;
 }

 @media (max-width: 568px) {
  .videoTag {
    min-width: 800px;
  }
  #headerTitle{
    font-size: 15px;
  }
}

 .videoTag{
  position: relative;
  top: 0px;
  width: 100%;
  filter: brightness(50%);
 }

 .jumbotronText{
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 2;
 }

 .jumbotronAcLogo{
  width: 200px;
  animation: logoSpin 2s linear 2s 1;
 }

 @keyframes logoSpin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(90deg); }
}

 /* carousel section */

 .carouselDiv{
  margin: auto;
  max-width: 600px;
 }

 .carouselText{
  position: relative;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0) , rgba(0, 0, 0, 0.333), rgba(0, 0, 0, 0.333),rgba(0, 0, 0, 0));
  z-index: 10;
  top:-290px;
  overflow: visible;
 }

 @media (min-width: 768px) {
  .carouselText {
    top: -360px;
  }
}

@media (max-width: 500px) {
  .carouselText {
    top: -250px;
  }
}

@media (max-width: 428px) {
  .carouselText {
    top: -150px;
  }
}

@media (max-width: 380px) {
  .carouselText {
    top: -200px;
  }
}

 /* About us section */


 /* Testimonials Section */

 .testimonialsDiv{
  
  margin: auto;
  padding: 100px 0 0 0;
  max-width: 900px;
  min-height: 450px;
 }

 
 /* Contact us & Enquiry Form section */

 .enquiryFormDiv{
  max-width: 700px;
  margin: auto;
  padding: 50px;
 }

 /* logout page  */

 .logoutButtonDiv{
  height: 300px;
 }

 #logoutButton{
  display: block;
  width: 200px;

 }
 
 
 /* Footer Section */

.footerLogo{
  width: 55px;
  height:60px;
  padding-top:5px;
  padding-bottom: 5px;
}


/* suspense loader */

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  margin: 100px auto;

  
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}